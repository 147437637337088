<template>
    <div>
        <a-modal :visible="visible" title="修改" @cancel="cancel" :footer="null">
            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" :colon="false" @submit="handleSubmit">

                <a-form-item label="产值(元)">
                    <a-input-number :formatter="$inputNumberFormat" v-decorator="['income',
                        {
                            initialValue: detail.income,
                            rules: [{ required: true, message: '请输入！' }]
                        },
                    ]" style="width: 100%" />
                </a-form-item>

                <!-- <a-form-item label="支出(元)">
                    <a-input-number :formatter="$inputNumberFormat" v-decorator="['cost',
                        {
                            initialValue: detail.cost,
                            rules: [{ required: true, message: '请输入！' }]
                        },
                    ]" style="width: 100%" />
                </a-form-item> -->

                <a-form-item label="利润(元)">
                    <a-input-number :formatter="$inputNumberFormat" v-decorator="['profit',
                        {
                            initialValue: detail.profit,
                            rules: [{ required: true, message: '请输入！' }]
                        },
                    ]" style="width: 100%" />
                </a-form-item>

                <div class="right">
                    <a-space>
                        <a-button @click="cancel">取消</a-button>
                        <a-button type="primary" html-type="submit" :loading="loading">保存</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-modal>
    </div>
</template>
  
  
<script>
import {
    update,
} from '../api'
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        detail: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
        };
    },

    methods: {

        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    console.log("values", values);
                    this.loading = true;
                    update({
                        id: this.detail.id,
                        ...values,
                    }).then(() => {
                        this.cancel();
                        this.$emit('refresh')
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            });
        },

        cancel() {
            this.form.resetFields();
            this.$emit("cancel");
        },
    },
};
</script>