<template>
    <div>
        <a-button v-if="$getPermission($route.path + '/upload')" @click="visible = true" type="primary">上传进度表</a-button>

        <a-modal :visible="visible" title="上传进度表" :footer="null" @cancel="visible = false" width="400px">

            <a-space>
                <a-month-picker placeholder="选择月份" :allowClear="false" v-model="date" />
                <input ref="upload" style="display: none" type="file" :multiple="false" @change="onChange" />
                <a-button type="primary" :loading="loading" @click="onClick">
                    上传文件
                </a-button>
            </a-space>

        </a-modal>

    </div>
</template>
  
<script>
import { uploadFile } from "../api";

export default {

    data() {
        return {
            visible: false,
            date: null,
            loading: false,
        };
    },

    methods: {
        onClick() {

            if (this.date) {
                this.$refs.upload.click();
            } else {
                this.$message.error("请先选择月份")
            }
        },

        onChange(e) {
            const files = e.target.files;
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("file", files[i]);
            }

            this.loading = true;

            uploadFile({
                date: this.date.format("YYYY-MM"),
                data,
            })
                .then((res) => {
                    console.log("upload res", res);
                    this.$emit('refresh')
                    this.visible = false;
                })
                .finally(() => {
                    this.loading = false;
                    this.date = null;
                    e.target.value = null;
                });
        },



    },
};
</script>