import request from '@/api/request'

export function fetchList(params) {
    return request({
        url: '/market-service/epc/prg/check/bus/list',
        params,
    })
}

export function uploadFile({ date, data }) {
    return request({
        url: '/market-service/epc/prg/common/bus/import?date=' + date,
        method: 'post',
        data
    })
}

export function sendMessage() {
    return request({
        url: '/market-service/epc/prg/check/bus/msgSend',
    })
}

export function update(data) {
    return request({
        url: '/market-service/epc/prg/check/date/update',
        data,
        method: "post",
    })
}

export function remove(data) {
    return request({
        url: '/market-service/epc/prg/check/date/delete',
        method: 'post',
        data,
    })
}

export function updateProject(data) {
    return request({
        url: '/market-service/epc/prg/check/prj/update',
        data,
        method: "post",
    })
}

export function removeProject(data) {
    return request({
        url: '/market-service/epc/prg/check/prj/delete',
        method: 'post',
        data,
    })
}