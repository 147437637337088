<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-space>
        <a-date-picker
          mode="year"
          placeholder="年份"
          @panelChange="panelChange"
          :value="year"
          format="YYYY"
          :open="open"
          @openChange="openChange"
          style="width: 120px"
          :allowClear="false"
        />

        <a-button @click="reset">重置</a-button>
        <Upload @refresh="getList" />

        <a-button v-if="$getPermission($route.path + '/confirm')" @click="onConfirm">进度确认</a-button>
      </a-space>

      <Padding />

      <a-table bordered :dataSource="list" :loading="loading" :pagination="false">
        <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">{{ text.view === 'sum' ? '合计' : index + 1 }}</template>
        </a-table-column>

        <a-table-column title="月份" width="120px" align="center" data-index="date" />

        <a-table-column title="产值(元)" align="right">
          <template slot-scope="text">
            <span
              style="color: #1890ff"
            >{{ text.income ? parseFloat(text.income).toLocaleString() : text.income }}</span>
          </template>
        </a-table-column>

        <a-table-column title="利润(元)" align="right">
          <template slot-scope="text">
            <span
              style="color: #1890ff"
            >{{ text.profit ? parseFloat(text.profit).toLocaleString() : text.profit }}</span>
          </template>
        </a-table-column>

        <a-table-column align="center" title="操作" width="160px" fixed="right">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                @click.prevent="openDetail(text)"
              >{{ text.view === 'sum' ? '项目汇总' : '项目详情' }}</a>
              <!-- <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent="edit(text)">修改</a>
                            <a href="#" class="danger" v-if="$getPermission($route.path + '/delete')"
              @click.prevent="deleteText(text)">删除</a>-->
            </a-space>
          </template>
        </a-table-column>
      </a-table>

      <Detail :visible="detailVisible" :detail="detail" @cancel="cancel" @refresh="getList" />
      <Edit :visible="editVisible" :detail="detail" @cancel="cancel" @refresh="getList" />
    </a-card>
  </div>
</template>
  
<script>
import moment from "moment";
import watermark from "@/mixins/watermark";
import Upload from "./components/upload.vue";
import Detail from "./components/detail.vue";
import Edit from "./components/edit.vue";

import { fetchList, remove, sendMessage } from "./api";

export default {
  mixins: [watermark],

  components: {
    Upload,
    Detail,
    Edit
  },

  data() {
    return {
      year: moment(),
      open: false,

      loading: false,
      list: [],

      detail: {},
      detailVisible: false,

      editVisible: false
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    panelChange(value) {
      this.year = value;
      this.open = false;
      this.getList();
    },
    openChange(value) {
      this.open = value;
    },

    getList() {
      this.loading = true;
      fetchList({
        date: this.year.format("YYYY")
      })
        .then(res => {
          this.loading = false;
          if (Array.isArray(res) && res.length > 0) {
            this.list = Object.freeze(res);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    reset() {
      this.year = moment();
      this.getList();
    },

    openDetail(text) {
      this.detail = text;
      this.detailVisible = true;
    },

    edit(text) {
      this.detail = text;
      this.editVisible = true;
    },

    cancel() {
      this.detailVisible = false;
      this.editVisible = false;
      this.detail = {};
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id
          }).then(() => {
            that.getList();
          });
        }
      });
    },

    onConfirm() {
      const that = this;
      this.$confirm({
        title: "是否确认当月产值进度，并通知经管部查看？",
        onOk() {
          const hide = that.$message.loading("发送中...", 0);
          sendMessage().finally(() => {
            hide();
          });
        }
      });
    }
  }
};
</script>